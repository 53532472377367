export default {
  'products.name': 'Name',
  'products.merchant': 'Merchant',
  'products.description': 'Description',
  'products.scan_barcode_type': 'Picking Scan Method',
  'products.product_code': 'Product Code',
  'products.sku': 'SKU',
  'products.product_category': 'Category',
  'products.weight_unit': 'Unit',
  'products.weight': 'Weight',
  'products.dimension': 'Dimension(CM)',
  'products.length': 'Length',
  'products.width': 'Width',
  'products.height': 'Height',
  'products.replenishment.safety.qty': 'Replen. Safety QTY.',
  'products.supplier': 'Supplier',
  'products.oversize_large_type': 'Oversize (Large)',
  'products.oversize_type': 'Oversize Type',
  'products.ean_code': 'EAN / UPC Code',
  'products.require_serial_number': 'Require S/N',
  'products.serial_number_length': 'Serial Number Length',
  'products.remark': 'Remark',
  'products.declared_info_title': 'Declaring Details',
  'products.declared_chinese_name': 'Declared Chinese Name',
  'products.declared_english_name': 'Declared English Name',
  'products.declared_price': 'Declared Value',
  'products.hscode': 'HS Code',
  'products.country_code_of_origin': 'Origin Country',
  'products.battery_section_type': 'Section Type',
  'products.battery': 'Battery ',
  'products.battery_un_number': 'UN Number',
  'products.battery_type': 'Battey Type',
  'products.battery_pi_type': 'P.I. Type',
  'products.battery_pack_qty': 'Pack Qty',
  'products.battery_pack_capacity_kilowatt': 'Battery Pack Capacity(WH)',
  'products.battery_cell_capacity_kilowatt': 'Battery Cell Capacity(WH)',
  'products.battery_pack_lithium_content_gram': 'Battery Pack Lithium Content(G)',
  'products.battery_cell_lithium_content_gram': 'Battery Cell Lithium Content(G)',
  'products.battery_net_weight': 'Net Weight(KG)',
  'products.status': 'Status',
  'products.create': 'Create Product',
  'products.create.fail': 'Fail to create product',
  'products.create.success': 'Product has been succesfully created',
  'products.update.fail': 'Fail to update product',
  'products.update': 'Update',
  'products.update.success': 'Product has been succesfully updated',
  'products.export_to_excel': 'Export the product(s) to excel',
  'products.download': 'Download',
  'products.upload': 'Upload Products',
  'products.qty_alert_threshold': 'Qty Alert Threshold',
  'products.require_gift_card': 'Require Gift Card',
  'products.sku_id': 'SKU ID',
  'products.shelf_life': 'Shelf Life',
  'products.sku_abc': 'SKU ABC',
  'products.sku_price': 'SKU Price',
  'products.volume': 'Volume',
  'products.net_weight': 'Net Weight',
  'products.min_count': 'Min Count',
  'products.max_count': 'Max Count',
  'products.production_location': 'Product Location',
  'products.specification': 'Specification',
  'products.sku_brand': 'SKU Brand',
  'products.item_size': 'Item Size',
  'products.item_color': 'Item Color',
  'products.item_style': 'Item Style',
  'products.product_value_category': 'Product Value Category',
  'products.pic_url': 'Picture URL',
  'products.is_sequence_sku': 'Is Sequence SKU',
  'products.is_breakable': 'Is Breakable',
  'products.is_dangerous': 'Is Dangerous',
  'products.is_precious': 'Is Precious',
  'products.is_abnormity': 'Is Abnormity',
  'products.is_need_product_batch_manage': 'Is Need Product Batch Manage',
  'products.is_need_exp_manage': 'Is Need Exp Manage',
  'products.is_material': 'Is Material',
  'products.additional_info': 'Additional Info',

  'products.download_selected': 'Downlaod selected barcode',
  'products.download-selected-confirm':
    'Are you sure you want to download selected product barcode?',
  'products.print_sku': 'Print Product SKU Barcode',
  'products.update_warehouse_product': 'Update Warehouse Product Info',
  'products.cbm': 'CBM',
  'products.upload_warehouse_product': 'Upload Warehouse Product',
  'products.warehouse_product_title': 'Warehouse Product Info',
  'products.push_to_wms_tooltip': 'Push To Ext. WMS',
  'products.push_to_wms_confirm': 'Are you sure you want to push product to External WMS?',

  'products.fail-to-update': 'Fail to update!',
};
