import { request } from 'umi';
import type { ProductTableListParams, UpdateProductDeclarationDataType } from './data';
import type { CreateFormValueType } from './components/CreateForm';
import type { UpdateFormValueType } from './components/UpdateForm';

const { JOR_WMS_PRODUCT_URL } = API;

export async function getProducts(params: ProductTableListParams | undefined) {
  return request(`${JOR_WMS_PRODUCT_URL}`, {
    method: 'GET',
    params,
  });
}

export async function getCreateProductOptions() {
  return request(`${JOR_WMS_PRODUCT_URL}/options`, {
    method: 'GET',
  });
}

export async function getProductMerchants(params: { sku: string }) {
  const { sku } = params;
  return request(`${JOR_WMS_PRODUCT_URL}/${sku}/merchants`, {
    method: 'GET',
  });
}

export async function exportProducts(params: ProductTableListParams | undefined) {
  return request(`${JOR_WMS_PRODUCT_URL}/export`, {
    method: 'GET',
    params,
    responseType: 'blob',
  });
}

export async function searchProductsByMerchantId(
  merchantId: number,
  params: ProductTableListParams | undefined,
) {
  return request(`${JOR_WMS_PRODUCT_URL}/merchant/${merchantId}`, {
    method: 'GET',
    params,
  });
}

export async function searchProductsByMerchantCode(
  merchantCode: string,
  params: ProductTableListParams | undefined,
) {
  return request(`${JOR_WMS_PRODUCT_URL}/merchant/code/${merchantCode}`, {
    method: 'GET',
    params,
  });
}

export async function getProductDetail(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_WMS_PRODUCT_URL}/${id}`, {
    method: 'GET',
  });
}

export async function createProduct(params: CreateFormValueType) {
  return request(`${JOR_WMS_PRODUCT_URL}`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function updateProduct(params: UpdateFormValueType) {
  const { id } = params;
  return request(`${JOR_WMS_PRODUCT_URL}/${id}`, {
    method: 'PUT',
    data: {
      ...params,
    },
  });
}

export async function updateProductDeclaration(params: UpdateProductDeclarationDataType) {
  const { id } = params;
  return request(`${JOR_WMS_PRODUCT_URL}/${id}/declaration`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function queryJorWmsProductOptions() {
  return request(`${JOR_WMS_PRODUCT_URL}/options`, {
    method: 'GET',
  });
}

export async function uploadToCreateProducts(formData: FormData) {
  return request(`${JOR_WMS_PRODUCT_URL}/upload`, {
    method: 'POST',
    data: formData,
  });
}

export async function downloadProductTemplate() {
  return request(`${JOR_WMS_PRODUCT_URL}/download/template`, {
    method: 'GET',
    responseType: 'blob',
  });
}
